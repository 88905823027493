import { DropdownTextItemType } from "components/atoms/Benchmark/DropdownText";
import { capitalize } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { useCallback, useMemo, useState } from "react";
import { beautifyBasecat } from "utils/beautify";
import { compareString } from "utils/compareString";
import { ColorNames, Gender, Category } from "utils/constants";
import { materials as materialList } from "utils/materials";
import { PriceRange } from "components/molecules/PriceRangeChartPopover";
import {
  normalizeProductsPriceData,
  normalizeProductsDateData,
} from "utils/normalizeProductsData";
import { RangeType } from "components/molecules/DateRangeChartPopover";
import DropdownList from "components/atoms/ExploreTrends/DropdownList";
import BigSpinner from "components/atoms/BigSpinner";
import PriceRangeChart from "components/molecules/Benchmark/PriceRangeChart";
import DateRangeChart from "components/molecules/Benchmark/DateRangeChart";

const SourceFilterOptions = [
  {
    name: "Retailers brands",
    value: "retailers_brands",
  },
  {
    name: "Digital",
    value: "digital",
  },
  {
    name: "Secondhand",
    value: "secondhand",
  },
  {
    name: "Outlet",
    value: "outlet",
  },
  {
    name: "Retailers brands korea",
    value: "retailers_brands_korea",
  },
];

export const GenderOptions = Object.entries(Gender).map(
  ([key, value]: string[]) => ({
    name: value,
    value: key,
  })
);

interface IBenchmarkFiltersProps {
  catalogType?: DropdownTextItemType;
  setCatalogType: (value?: DropdownTextItemType) => void;
  colors?: DropdownTextItemType[];
  setColors: (value?: DropdownTextItemType[]) => void;
  genders?: DropdownTextItemType[];
  setGenders: (value?: DropdownTextItemType[]) => void;
  basecats?: DropdownTextItemType[];
  setBasecats: (value?: DropdownTextItemType[]) => void;
  brand?: DropdownTextItemType;
  setBrand: (value?: DropdownTextItemType) => void;
  materials?: DropdownTextItemType[];
  setMaterials: (value?: DropdownTextItemType[]) => void;
  benchmarkMetadata?: any[];
  isLoadingbenchmarkMetadata?: boolean;
  priceRange: RangeType;
  setPriceRange: (value: RangeType) => void;
  dateRange: RangeType;
  setDateRange: (value: RangeType) => void;
}

const BenchmarkFilters: React.FC<IBenchmarkFiltersProps> = ({
  catalogType,
  setCatalogType,
  colors,
  setColors,
  genders,
  setGenders,
  basecats,
  setBasecats,
  brand,
  setBrand,
  materials,
  setMaterials,
  benchmarkMetadata,
  isLoadingbenchmarkMetadata,
  priceRange,
  setPriceRange,
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslation("common");
  const [openFilter, setOpenFilter] = useState<string | null>(null);

  const BasecatOptions = Category.map((value: string) => ({
    name: capitalize(beautifyBasecat(t(value))),
    value: value,
  }));

  const materialOptions = useMemo(
    () =>
      materialList
        .sort((a, b) => compareString(a, b))
        .map((item: string) => ({
          name: capitalize(
            item
              .split(" ")
              .map((strValue) => t(strValue.toLowerCase()))
              .join(" ")
          ),
          value: item.replace(" ", "_"),
        })),
    []
  );

  const handleOpenDropdown = (id: string) => {
    if (openFilter === id) {
      setOpenFilter(null);
    } else {
      setOpenFilter(id);
    }
  };

  const handleFilter = useCallback(
    (name: string, value: string, source: string) => {
      const selectedData = {
        name,
        value,
      };

      if (source === "source") {
        setCatalogType(selectedData);
      } else if (source === "gender") {
        setGenders(getFilteredData(selectedData, genders));
      } else if (source === "categories") {
        setBasecats(getFilteredData(selectedData, basecats));
      } else if (source === "color") {
        setColors(getFilteredData(selectedData, colors));
      } else if (source === "brand") {
        if (selectedData.value === brand?.value) {
          setBrand();
        } else {
          setBrand(selectedData);
        }
      } else if (source === "material") {
        setMaterials(getFilteredData(selectedData, materials));
      }
    },
    [genders, basecats, colors, materials, brand]
  );

  const brandData = useMemo(() => {
    const brandList: string[] = [];

    benchmarkMetadata?.forEach(
      (item) => !brandList?.includes(item.brand) && brandList.push(item.brand)
    );

    return brandList;
  }, [benchmarkMetadata]);

  const priceChartData = useMemo(
    () =>
      normalizeProductsPriceData(
        benchmarkMetadata?.filter((item) => !!item.price_usd) || []
      ),
    [benchmarkMetadata]
  );

  const dateChartData = useMemo(
    () =>
      normalizeProductsDateData(
        benchmarkMetadata?.filter((item) => !!item.first_seen) || []
      ),
    [benchmarkMetadata]
  );

  const filterDropdownOption = [
    {
      id: "source",
      title: t("source"),
      values: catalogType ? catalogType.value : "",
      isCheckBox: true,
      isMultiSelect: false,
      isSame: true,
      options: SourceFilterOptions.map((item) => ({
        ...item,
        name: t(item.name),
      })),
    },
    {
      id: "gender",
      title: t("simple_gender"),
      values: genders ? genders?.map((item) => item.value) : ["all"],
      isCheckBox: true,
      isMultiSelect: true,
      options: [
        {
          name: t("all_genders"),
          value: "all",
        },
        ...GenderOptions.map((item) => ({
          ...item,
          name: t(item.name),
        })),
      ],
    },
    {
      id: "categories",
      title: t("Category"),
      values: basecats ? basecats.map((item) => item.value) : ["all"],
      isCheckBox: true,
      isMultiSelect: true,
      options: [
        {
          name: t("all_categories"),
          value: "all",
        },
        ...BasecatOptions,
      ],
    },
    {
      id: "color",
      title: t("color"),
      values: colors ? colors.map((item) => item.value) : ["all"],
      isCheckBox: true,
      isMultiSelect: true,
      isColor: true,
      options: [
        { name: t("all_colors"), value: "all" },
        ...ColorNames.map((item) => ({
          ...item,
          name: t(item.value),
        })),
      ],
    },
    {
      id: "material",
      title: t("material"),
      values: materials ? materials.map((item) => item.value) : ["all"],
      isCheckBox: true,
      isMultiSelect: true,
      options: [{ name: t("all_materials"), value: "all" }, ...materialOptions],
    },
    {
      id: "brand",
      title: t("brand"),
      values: brand ? brand.value : "",
      isCheckBox: true,
      isMultiSelect: false,
      isSearch: true,
      isAlphabetLabel: true,
      options: brandData
        .sort((a, b) => compareString(a, b))
        .map((item) => ({
          name: item,
          value: item,
        })),
    },
    {
      id: "date",
      title: t("launch_date"),
      component: isLoadingbenchmarkMetadata ? (
        <div className="w-full h-[120px] flex items-center justify-center">
          <BigSpinner className="w-10 h-10" />
        </div>
      ) : (
        dateChartData.length > 0 && (
          <DateRangeChart
            range={dateRange}
            onApply={(range: PriceRange) => {
              setDateRange(range);
            }}
            chartData={dateChartData}
            className="!p-0"
            hideTitle
            hideActionButtons
            disableInput
            chartWrapperClassName="!w-full"
          />
        )
      ),
    },
    {
      id: "price_range",
      title: t("price_range"),
      component: isLoadingbenchmarkMetadata ? (
        <div className="w-full h-[120px] flex items-center justify-center">
          <BigSpinner className="w-10 h-10" />
        </div>
      ) : (
        dateChartData.length > 0 && (
          <PriceRangeChart
            range={priceRange}
            onApply={(range: PriceRange) => {
              setPriceRange(range);
            }}
            chartData={priceChartData}
            className="!p-0"
            hideTitle
            hideActionButtons
            chartWrapperClassName="!w-full"
          />
        )
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col py-2 overflow-y-scroll">
      {filterDropdownOption?.map((item) => (
        <DropdownList
          onSelectItem={handleFilter}
          key={`${item.id}-${item.title}`}
          open={
            openFilter === item.id ||
            item.id === "date" ||
            item.id === "price_range"
          }
          disableOpen={item.id === "date" || item.id === "price_range"}
          onOpen={() => handleOpenDropdown(item.id)}
          {...item}
        />
      ))}
    </div>
  );
};

export default BenchmarkFilters;

export const getFilteredData = (
  selectedData: { name: string; value: string },
  options?: DropdownTextItemType[]
) => {
  if (selectedData.value === "all") {
    return undefined;
  } else {
    if (options) {
      const filteredData = options.filter(
        (item) => item.value === selectedData.value
      );
      if (filteredData.length) {
        return options.length > 1
          ? options.filter((item) => item.value !== selectedData.value)
          : undefined;
      } else {
        return [...options, selectedData];
      }
    } else {
      return [selectedData];
    }
  }
};
