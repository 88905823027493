import Icon from "../Icon";
import cn from "classnames";
import ArrowDownIcon from "public/icons/chevron_down_16.svg";
import CheckBox from "../CheckBox";
import ColorTile from "../ColorTile";
import { useMemo, useState } from "react";
import { groupOptionsByFirstLetter } from "utils/normalizeString";
import FlagIcon from "./FlagIcon";
import SearchInput from "../SearchInput";
import CheckIcon from "public/icons/check_16.svg";
import useTranslation from "next-translate/useTranslation";

interface DropdownListProps {
  id?: string;
  icon?: any;
  open?: boolean;
  title?: string;
  values?: string[] | string;
  onOpen: () => void;
  component?: any;
  isSame?: boolean;
  isColor?: boolean;
  isRegion?: boolean;
  isSearch?: boolean;
  isCheckBox?: boolean;
  selectedCount?: number;
  isGroupButton?: boolean;
  onSelectItem?: Function;
  optionClassName?: string;
  groupContainerClassName?: string;
  buttonGroupClassName?: string;
  isAlphabetLabel?: boolean;
  options?: {
    value: string;
    name: string;
  }[];
  disableOpen?: boolean;
}

const DropdownList: React.FC<DropdownListProps> = ({
  id,
  open,
  icon,
  title,
  values,
  onOpen,
  options,
  children,
  component,
  isSame = false,
  isColor = false,
  isRegion = false,
  isSearch = false,
  isCheckBox = false,
  isGroupButton = false,
  onSelectItem,
  selectedCount,
  optionClassName,
  groupContainerClassName,
  buttonGroupClassName,
  isAlphabetLabel = false,
  disableOpen = false,
}) => {
  const { t } = useTranslation("common");
  const [keyword, setKeyword] = useState<string>("");
  const [alphabetLabelList, setAlphabetLabelList] = useState<string[]>([]);

  const optionGroup = useMemo(
    () =>
      groupOptionsByFirstLetter(
        options?.filter(
          (item) =>
            keyword === "" ||
            item.value.toLowerCase().includes(keyword?.toLowerCase())
        ) || []
      ),
    [options, keyword]
  );

  return (
    <div className={"w-full"} key={`${id}`}>
      <div
        className="flex justify-between items-center rounded-md w-full px-4 py-2.5 hover:bg-gray-20 active:bg-gray-30 cursor-pointer"
        onClick={!disableOpen ? onOpen : () => {}}
      >
        <div className="flex items-center justify-start gap-2">
          <Icon component={icon} viewBox="0 0 16 16" width={16} height={16} />
          <div className="text-xs leading-normal font-medium text-typography-title capitalize">
            {title}
            {selectedCount && `(${selectedCount})`}
          </div>
        </div>
        {!disableOpen && (
          <button>
            <Icon
              component={ArrowDownIcon}
              viewBox="0 0 16 16"
              width={12.5}
              height={12.5}
              className={cn("fill-black", open && "rotate-180")}
            />
          </button>
        )}
      </div>

      {open && (
        <div className={cn("px-4 pb-2", groupContainerClassName)}>
          {isSearch && (
            <SearchInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className="!h-8 !pl-8 !border-0 !bg-transparent"
              containerClassName="!h-8 !p-0"
              beforeIconClassName="!top-2 !left-2"
              placeholder={title}
              namespace={""}
            />
          )}
          <div
            className={cn(
              "max-h-[200px] overflow-y-scroll px-2",
              isGroupButton &&
                "flex items-start justify-start gap-2 max-w-full flex-wrap",
              buttonGroupClassName
            )}
          >
            {Object.entries(optionGroup).map(
              ([alphabetLabel, optionlist], index) => (
                <div key={`${alphabetLabel}-${index}`}>
                  {isAlphabetLabel && (
                    <div className="bg-gray-30 text-xs rounded-sm px-2 py-1 text-typography-title">
                      {alphabetLabel}
                    </div>
                  )}
                  {optionlist.map((option, key) => {
                    let alphabetLabel = "";
                    if (
                      isAlphabetLabel &&
                      !alphabetLabelList.includes(option.value[0])
                    ) {
                      alphabetLabelList.push(option.value[0].toLowerCase());
                      alphabetLabel = option.value[0].toUpperCase();
                    }

                    if (isGroupButton) {
                      return (
                        <button
                          className={cn(
                            "py-2 px-3 rounded-[100px] border-[0.5px] border-border-normal text-title text-xs leading-[120%]",
                            (isSame
                              ? values === option.value
                              : values?.includes(option.value)) &&
                              "!text-white bg-gray-950 border-gray-950"
                          )}
                          onClick={() =>
                            onSelectItem &&
                            onSelectItem(option.name, option.value, id)
                          }
                        >
                          {option.name}
                        </button>
                      );
                    }

                    const isActive =
                      !isCheckBox &&
                      (isSame
                        ? values === option.value
                        : values?.includes(option.value));

                    return (
                      <div
                        key={`${key}-${option.value}`}
                        className={cn(
                          "px-2 py-1 text-typography-title text-xs h-8 flex items-center justify-between gap-1 hover:bg-gray-10 active:bg-gray-20 cursor-pointer rounded-md",
                          !isCheckBox && "pl-2",
                          option.disabled &&
                            "!text-typography-body-disabled cursor-not-allowed active:!bg-transparent",
                          optionClassName
                        )}
                        onClick={() =>
                          onSelectItem &&
                          !option.disabled &&
                          onSelectItem(option.name, option.value, id)
                        }
                      >
                        <div className="flex items-center">
                          {isCheckBox && (
                            <CheckBox
                              checked={
                                isSame
                                  ? values === option.value
                                  : values?.includes(option.value)
                              }
                              width={16}
                              height={16}
                              onClick={(e: any) => e.stopPropagation()}
                              onChange={() => {}}
                            />
                          )}
                          {isColor && (
                            <ColorTile
                              color={option.value}
                              className="w-3 h-3 rounded-full"
                            />
                          )}
                          {isRegion && <FlagIcon countryCode={option.value} />}
                          <div
                            className={
                              isActive
                                ? "text-cta-600 font-medium"
                                : "text-typography-heading"
                            }
                          >
                            {option.name}
                          </div>
                        </div>
                        {isActive && (
                          <Icon
                            className="shrink-0"
                            component={CheckIcon}
                            viewBox="0 0 16 16"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
          {component}
        </div>
      )}
    </div>
  );
};

export default DropdownList;
