import { QueryClient } from "react-query";
import GlobalApi from "./GlobalApi";
import { AxiosResponse } from "axios";

export type BenchmarkSetupsType = (string | number)[];

export interface BenchmarkSetupsParamsType {
  brand?: string;
  benchmark_info?: any;
  analysis_type?: string;
  dropdown?: string;
  region?: string;
  market?: string;
  retailer?: string;
  basecat?: string;
  subcat?: string;
  gender?: string;
  color?: string;
  material?: string;
  catalog_name?: string;
  competitors?: string[] | string;
  style_id?: string;
  catgroup?: string;
  limit?: number;
  offset?: number;
  p?: number;
  first_seen?: string;
  fs_filter?: number;
  sdate?: string;
  edate?: string;
}

export interface BenchmarkTrendScoreParamsType
  extends BenchmarkSetupsParamsType {
  competitors?: string;
}

export interface BenchmarkUpDownMarketParamsType
  extends BenchmarkSetupsParamsType {
  competitors?: string;
  trend_type?: string;
  catgroup?: string;
}

export interface BenchmarkBreakdownStyleParamsType
  extends BenchmarkSetupsParamsType {
  competitors?: string;
  trend_type?: string;
  chunk?: string;
}

export interface BenchmarkTsDistributionParamsType
  extends BenchmarkSetupsParamsType {
  competitors?: string;
  breakdown?: string;
}

export interface BenchmarkStyleRecommendedParamsType
  extends BenchmarkSetupsParamsType {
  style_id?: string;
  breakdown?: string;
  similar?: string;
  competitors?: string;
  catgroup?: string;
}

export interface BenchmarkCompetitorTsParamsType
  extends BenchmarkSetupsParamsType {
  competitors?: string;
  breakdown?: string;
  trend_type?: string;
  p?: number;
}

export interface BenchmarkBreakdownPathwayParamsType
  extends BenchmarkSetupsParamsType {
  competitors?: string;
  group_id?: string;
}

export type BenchmarkBreakdownResultType = {
  group_id: string;
  brand: string;
  gender: string;
  catgroup: string;
  basecat: string;
  style_key: string;
  style_value: string;
  pct_change: number;
  product_count: number;
  total_count: number;
  pct_of_total: number;
  style_name: string;
};

export interface InvitationPayload {
  teamId: number;
  inviteEmails: string[];
}

export interface DominantBrandPayload {
  benchmark_info?: string;
  analysis_type: string;
  style_id: string;
  competitors: string;
  market?: string;
  region?: string;
  retailer?: string;
  basecat?: string;
  gender?: string;
  color?: string;
  material?: string;
  brand: string;
  catalog_name?: string;
  limit?: number;
}

export interface DistributionBrandPayload {
  group_id: string;
  hasmarkdown: number;
  brand: string;
}

export interface DistributionGroupIdPayload {
  group_id: string;
  hasmarkdown: number;
}

export default class BenchmarkGlobalApi extends GlobalApi {
  userToken: string;
  teamId: number;
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
    if (userToken) this.userToken = userToken;
    if (teamId) this.teamId = teamId;
  }

  async getBenchmarkSetups(
    params: BenchmarkSetupsParamsType
  ): Promise<string[][]> {
    return await this.query(`/trends/benchmarks/setup`, params);
  }

  async getBenchmarkTrendScore(
    params: BenchmarkTrendScoreParamsType
  ): Promise<any> {
    return await this.query("/trends/score", {
      ...params,
      p: Math.floor((Math.random() * 100) % 10),
    });
  }

  async getBenchmarkUpDownMarket(
    params: BenchmarkUpDownMarketParamsType
  ): Promise<any> {
    return await this.query("/trends/breakdown/updownmarket", {
      ...params,
      p: Math.floor((Math.random() * 100) % 10),
    });
  }

  async getBenchmarkBreakdownStyle(
    params: BenchmarkBreakdownStyleParamsType
  ): Promise<BenchmarkBreakdownResultType[]> {
    return await this.query("/trends/breakdown/style", params);
  }

  async getBenchmarkTsDistribution(
    params: BenchmarkTsDistributionParamsType
  ): Promise<any> {
    return await this.query("/trends/benchmarks/tsdist", params);
  }

  async getBenchmarkStyleRecommended(
    params: BenchmarkStyleRecommendedParamsType
  ): Promise<any> {
    return await this.query("/trends/styles/recommended", params);
  }

  async getBenchmarkCompetitorTs(
    params: BenchmarkCompetitorTsParamsType
  ): Promise<any> {
    return await this.query("/trends/competitors/ts", params);
  }

  async getBenchmarkBreakdownPathway(
    params: BenchmarkBreakdownPathwayParamsType
  ): Promise<any> {
    return await this.query("/trends/breakdown/pathway", params);
  }
  async inviteMember(
    id: number,
    payload: InvitationPayload
  ): Promise<AxiosResponse<any>> {
    return await this.client.post(
      `/benchmark-projects/${id}/invitations`,
      payload
    );
  }

  async removeGuestFromBenchmarkProject({
    benchmarkId,
    userId,
  }: {
    benchmarkId: number;
    userId: number;
  }) {
    return await this.client.delete(
      `/benchmark-projects/${benchmarkId}/invitations/remove-guest/${userId}`
    );
  }

  async resendBenchmarkProjectInvite(projectId: number, email: string) {
    return await this.client.post(
      `/benchmark-projects/${projectId}/invitations/resend-invite`,
      {
        email,
      }
    );
  }

  async checkBenchmarkProjectInvitation(
    token: string,
    code?: string
  ): Promise<any> {
    return await this.client.get(
      `/benchmark-projects/invitations/check-invite/${token}${
        code ? `/${code}` : ""
      }`
    );
  }

  async getDominantBrand(params: DominantBrandPayload) {
    return await this.query(`/trends/styles/pctcatalog/`, params);
  }

  async getDistributionBrand(params: DistributionBrandPayload) {
    return await this.query(`/trends/styles/distribution/brand`, params);
  }

  async getDistributionGroupId(params: DistributionGroupIdPayload) {
    return await this.query(`/trends/styles/distribution/group_id`, params);
  }
}
