import React, { RefObject, useRef, useState } from "react";
import cn from "classnames";
import Icon from "components/atoms/Icon";
// import { theme } from "tailwind.config";
import CloseIcon from "public/icons/close.svg";
import HidePasswordIcon from "public/icons/hide_password.svg";
import ShowPasswordIcon from "public/icons/show_password.svg";
import InfoTooltip from "./InfoTooltip";
import useTranslation from "next-translate/useTranslation";

export interface InputProps {
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  beforeIconClassName?: string;
  afterIconClassName?: string;
  label?: string;
  errorMessage?: string | false;
  icon?: any;
  afterIcon?: any;
  iconProps?: any;
  afterIconProps?: any;
  multiline?: boolean;
  gray?: boolean;
  clearText?: Function;
  type?: string;
  errorClassName?: string;
  inputRef?: RefObject<HTMLInputElement>;
  rounded?: boolean;
  description?: string;
  onDescriptionClick?: Function;
  descriptionClassName?: string;
  required?: boolean;
  inputWrapperClassName?: string;
  autocomplete?: string;
  fieldClassName?: string;
  lowerError?: boolean;
  optional?: boolean;
  optionalText?: string;
  labelTip?: string;
  boldLabel?: boolean;
}

export type InputPropsType = InputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const Input: React.FC<InputPropsType> = ({
  className,
  labelClassName,
  containerClassName = "",
  beforeIconClassName = "",
  afterIconClassName = "",
  afterIconProps = {},
  label,
  errorMessage,
  icon,
  iconProps = {},
  multiline,
  gray,
  clearText,
  value,
  type,
  placeholder,
  disabled,
  errorClassName,
  afterIcon,
  inputRef,
  rounded,
  required,
  description,
  onDescriptionClick,
  descriptionClassName,
  children,
  inputWrapperClassName,
  fieldClassName,
  lowerError,
  optional,
  labelTip,
  boldLabel,
  optionalText,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);
  const { t } = useTranslation("common");

  const inputClassName = cn(
    `bg-${disabled ? "gray-40" : "white"}` +
      "placeholder-shown:overflow-hidden placeholder-shown:text-ellipsis py-2 px-4 rounded-md border border-border-normal w-full text-sm focus:outline-none focus:border-border-focus focus:border-2",
    className,
    errorMessage && "border-border-error",
    icon && "pl-12",
    disabled && "hover:border-gray-40 text-gray-50",
    !multiline && "h-10",
    gray && "bg-gray-100",
    clearText && "pr-12",
    rounded && "!py-2 !rounded-full !text-xs !h-8"
  );

  const handleHidePassword = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  // Fix textarea for dynamic height

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setValue] = useState("");

  const handleChange = (evt: any) => {
    if (!multiline) return;
    const val = evt.target?.value;
    setValue(val);
  };

  return (
    // don't change styles here, it can affect all inputs all over the project, change it via class names
    <div className={cn("flex flex-col relative pb-4", containerClassName)}>
      {label && (
        <label
          className={cn(
            "label-100 relative flex items-center w-fit mb-1",
            boldLabel && "body-100 font-semibold",
            labelClassName
          )}
        >
          {label}
          {required && (
            <p
              className={cn(
                "text-cobalt-400 text-xs absolute -right-2 top-0",
                !!labelTip && "right-3"
              )}
            >
              *
            </p>
          )}
          {optional && (
            <span className="body-100-subtle text-gray-50 pl-1">{`- ${
              optionalText || t("optional")
            }`}</span>
          )}
          {labelTip && <InfoTooltip dataTip={labelTip} className="ml-2" />}
        </label>
      )}

      <div className={cn("relative", inputWrapperClassName)}>
        {icon && (
          <Icon
            component={icon}
            className={cn(
              "absolute top-2 left-4",
              rounded && "!top-1",
              beforeIconClassName
            )}
            {...iconProps}
          />
        )}
        {multiline ? (
          <textarea
            rows={3}
            ref={textAreaRef}
            onKeyDown={handleChange}
            className={cn(inputClassName, "!py-3")}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <input
            className={cn(inputClassName, fieldClassName)}
            value={value}
            ref={inputRef}
            type={inputType}
            placeholder={placeholder}
            disabled={disabled}
            {...rest}
          />
        )}
        {clearText && value && (
          <button
            onClick={() => clearText()}
            className="absolute rounded-md top-[1.8px] right-[1.8px] flex items-center justify-center w-[36.5px] h-[36px] bg-transparent hover:bg-gray-40"
          >
            <Icon
              width={10}
              height={10}
              component={CloseIcon}
              className={cn(afterIconClassName)}
              viewBox="0 0 14 14"
            />
          </button>
        )}
        {afterIcon && (
          <Icon
            component={afterIcon}
            className="absolute top-2 right-4 cursor-pointer"
            {...afterIconProps}
          />
        )}
        {type === "password" &&
          (inputType === "password" ? (
            <Icon
              onClick={handleHidePassword}
              component={HidePasswordIcon}
              className="absolute top-2 right-4 cursor-pointer"
            />
          ) : (
            <Icon
              onClick={handleHidePassword}
              component={ShowPasswordIcon}
              className="absolute top-2.5 right-3.5 cursor-pointer"
            />
          ))}
      </div>
      {children}
      {!!errorMessage && (
        <div
          className={cn(
            "error-100 absolute -bottom-0.5 whitespace-nowrap",
            lowerError && "-bottom-[17px]",
            errorClassName
          )}
        >
          {errorMessage}
        </div>
      )}
      {!!description && (
        <span
          onClick={() => onDescriptionClick && onDescriptionClick()}
          className={cn(
            "-bottom-1 hint-100 text-sm text-gray-60 mt-1",
            onDescriptionClick && "cursor-pointer",
            errorMessage && "-bottom-4",
            descriptionClassName
          )}
        >
          {description}
        </span>
      )}
    </div>
  );
};

export default Input;
